import { RouteRecordRaw } from 'vue-router';

// @ts-ignore
const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    meta: {
      renderMenu: false
    },
    component: () => import('@/components/layout/FrontView.vue'),
    children: [
      {
        path: '/login',
        name: 'Login',
        meta: {
          icon: 'LoginOutlined',
          view: 'blank',
          target: '_blank',
          cacheable: false,
        },
        component: () => import('@/pages/login'),
      },
    ],
  },
  {
    path: '/403',
    name: '403',
    props: true,
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/Exp403.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    props: true,
    meta: {
      icon: 'CreditCardOutlined',
      renderMenu: false,
      cacheable: false,
      _is404Page: true,
    },
    component: () => import('@/pages/Exp404.vue'),
  },
  {
    path: '/workplace',
    name: '工作台',
    meta: {
      icon: 'DashboardOutlined',
      cacheable: false,
    },
    component: () => import('@/pages/workplace'),
  },
  {
    path: '/apply',
    name: '上下分管理',
    meta: {
      icon: 'ColumnHeightOutlined',
      renderMenu: true
    },
    children: [
      {
        path: 'up',
        name: '上分申请',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/apply/up'),
      },
      {
        path: 'down',
        name: '下分申请',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/apply/down'),
      },
      {
        path: 'record',
        name: '上下分记录',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/apply/record'),
      },
      {
        path: 'recharge',
        name: '银行充值',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/bank/Recharge.vue'),
      },
      {
        path: 'draw',
        name: '银行提现',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/bank/Draw.vue'),
      },
    ],
  },
  {
    path: '/pledge',
    name: '定存管理',
    meta: {
      icon: 'AuditOutlined',
      renderMenu: true,
    },
    children: [
      {
        path: 'index/:config_id?',
        name: '定存订单',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/pledge'),
      },
      {
        path: 'config',
        name: '定存配置',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/pledge/config'),
      },
    ],
  },
  {
    path: '/leverage',
    name: '杠杆管理',
    meta: {
      icon: 'ExpandAltOutlined',
      renderMenu: true,
    },
    children: [
      {
        path: 'order/:sn?',
        name: '订单列表',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/number/order'),
      },
      {
        path: 'room',
        name: '房间列表',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/number/room'),
      },
      {
        path: 'generate',
        name: '数据采集',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/number/generate'),
      },
      {
        path: 'award',
        name: '开奖列表',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/number/award/index'),
      },
    ],
  },
  {
    path: '/account',
    name: '用户管理',
    meta: {
      icon: 'UserOutlined',
      renderMenu: true,
    },
    children: [
      {
        path: 'user',
        name: '用户列表',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/user'),
      },
      {
        path: 'auth',
        name: '实名认证',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/user/auth'),
      },
      {
        path: 'level',
        name: '用户推荐',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/user/level/index'),
      },
      {
        path: 'robot',
        name: '机器人列表',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/user/robot'),
      },
      {
        path: 'notice',
        name: '系统通知',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/user/notice'),
      },
      {
        path: 'feedback',
        name: '用户反馈',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/user/feedback'),
      },
      // {
      //   path: 'admin',
      //   name: '管理员列表',
      //   meta: {
      //     cacheable: false,
      //   },
      //   component: () => import('@/pages/admin'),
      // },
    ],
  },
  {
    path: '/agent',
    name: '代理管理',
    meta: {
      icon: 'LinkOutlined',
      renderMenu: true,
    },
    children: [
      {
        path: 'list',
        name: '代理列表',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/agent/list'),
      },
      {
        path: 'salesman/',
        name: '业务员列表',
        meta: {
          cacheable: false, renderMenu: true
        },
        component: () => import('@/pages/agent/salesman'),
      },
    ],
  },
  {
    path: '/currency',
    name: '货币管理',
    meta: {
      icon: 'StrikethroughOutlined',
      renderMenu: true,
    },
    children: [
      {
        path: 'wallet',
        name: '用户钱包',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/currency/wallet'),
      },
      {
        path: 'bank',
        name: '银行卡',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/bank/index'),
      },
      {
        path: 'crypto',
        name: '加密货币',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/currency/crypto'),
      },
      {
        path: 'fiat',
        name: '法定货币',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/currency/fiat'),
      },
      {
        path: 'rate',
        name: '币种汇率',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/currency/rate'),
      },

    ],
  },
  {
    path: '/counter',
    name: '兑换管理',
    meta: {
      icon: 'SwapOutlined',
      renderMenu: true,
    },
    children: [
      {
        path: 'list',
        name: '柜台列表',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/counter/index'),
      },
      {
        path: 'rule',
        name: '兑换规则',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/counter/rule'),
      },
      {
        path: 'order',
        name: '兑换订单',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/counter/order'),
      },
      {
        path: 'flash-order',
        name: '闪兑订单',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/counter/flash'),
      }
    ],
  },
  {
    path: '/info',
    name: '资讯管理',
    meta: {
      icon: 'InfoOutlined',
      renderMenu: true,
    },
    children: [
      {
        path: '',
        name: '资讯列表',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/info/index'),
      },
      // {
      //   path: 'zh-tw',
      //   name: '资讯-繁體中文',
      //   meta: {
      //     cacheable: false,
      //   },
      //   component: () => import('@/pages/info/index'),
      // },
      // {
      //   path: 'en-us',
      //   name: '资讯-English',
      //   meta: {
      //     cacheable: false,
      //   },
      //   component: () => import('@/pages/info/index'),
      // },
    ],
  },
  {
    path: '/petition',
    name: '请愿书',
    meta: {
      icon: 'CopyOutlined',
      renderMenu: true,
    },
    children: [
      {
        path: 'zh-cn',
        name: '请愿书-简体中文',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/petition/index'),
      },
      {
        path: 'zh-tw',
        name: '请愿书-繁體中文',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/petition/index'),
      },
      {
        path: 'en-us',
        name: '请愿书-English',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/petition/index'),
      },
    ],
  },
  {
    path: '/setting',
    name: '网站设置',
    meta: {
      icon: 'SettingOutlined',
      renderMenu: true,
    },
    children: [
      {
        path: 'basic',
        name: '基础设置',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/setting/basic'),
      },
      {
        path: 'email',
        name: '邮件设置',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/setting/email'),
      },
      {
        path: 'online_service',
        name: '在线客服',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/setting/online_service'),
      },
      {
        path: 'banner',
        name: '轮播图设置',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/setting/banner'),
      },
      // {
      //   path: 'shortcut',
      //   name: '快捷图标',
      //   meta: {
      //     cacheable: false,
      //   },
      //   component: () => import('@/pages/setting/shortcut/index'),
      // },
      {
        path: 'other',
        name: '其他配置',
        meta: {
          cacheable: false,
        },
        component: () => import('@/pages/setting/other/index'),
      },
    ],
  },
];

export default routes;
